import DatePicker from 'vue2-datepicker';
import appStrings from '../../../../utility/string.utility';
import addEditFmsMeter from './addEditFmsMeter';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'fmsMeter',
  components: {
    DatePicker,
    addEditFmsMeter
  },
  watch: {
    currentPage: function() {
      this.getAllFmsMeterData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAllFmsMeterData();
    }
  },
  data() {
    return {
      responseMsg: '',
      loader: false,
      entityType: {
        value: null,
        text: null
      },
      unitSubunit: {
        value: null,
        text: null
      },
      startDate: null,
      endDate: null,
      partyName: 'CUSTOMER',
      party: {
        name: null,
        id: null
      },

      meterNo: null,
      meterType: {
        value: null,
        text: null
      },
      isActive: true,
      showValueSetModal: false,
      addFmsMeterModal: false,
      totalRows: null,
      unsubscribe: null,
      id: null,
      isSuccess: false,
      showAlert: false,

      fmsMeterDetailsFields: [
        {
          key: 'entity_type',
          label: 'Entity Type'
        },
        {
          key: 'unit_name',
          label: 'Unit/Subunit'
        },

        {
          key: 'start_date',
          label: 'Start Date'
        },
        {
          key: 'end_date',
          label: 'End Date'
        },
        {
          key: 'party_type',
          label: 'Party Type'
        },
        {
          key: 'party_name',
          label: 'Party Name'
        },
        {
          key: 'meter_no',
          label: 'Meter No'
        },
        {
          key: 'active',
          label: 'Active'
        },
        {
          key: 'meter_code',
          label: 'Meter Type'
        }, 
        {
          key:'owner_value_meaning',
          label:'Ownership Type'
        }
      ],
      fmsMeterDetailsData: [],
      showPartyModal: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      fmsMeterData: {},
      modalFlag: false,
      meterId: null,
      addEditFlag: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.addEditFlag = false;
          this.addFmsMeterModal = true;
          this.meterId = null;
        }
      }
    });
  },
  methods: {
    checkUnitorSubUnit(entityType) {
      if (entityType === 'FMS_UNIT') {
        this.openValueSetModal('FMS_UNIT_DISPLAY_VSET');
      } else if (entityType === 'FMS_SUB_UNIT') {
        this.openValueSetModal('FMS_SUB_UNIT_DISPLAY_VSET');
      } else if (entityType === 'LEASE_UNIT') {
        this.openValueSetModal('LEASE_UNIT_VSET');
      } 
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY ||
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST
      ) {
        this.parent_value_set_id = this.entityType.value;
      }

      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.entityType.text = item.value_meaning;
        this.entityType.value = item.value_code;
        this.unitSubunit.text = null;
        this.unitSubunit.value = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY_VSET
      ) {
        this.unitSubunit.text = item.unit_display_name;
        this.unitSubunit.value = item.fms_unit_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY_VSET
      ) {
        this.unitSubunit.text = item.fms_sub_unit_name;
        this.unitSubunit.value = item.fms_sub_unit_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET) {
        this.unitSubunit.text = item.unit_name;
        this.unitSubunit.value = item.lease_prj_unit_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.METER_TYPE) {
        (this.meterType.text = item.value_meaning),
          (this.meterType.value = item.value_code);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_VSET) {
        this.party.name = item.customer_name;
        this.party.id = item.customer_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.UNIT_TYPE) {
        this.entityType.text = null;
        this.entityType.value = null;
      } else if (
        vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY_VSET ||
        vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY_VSET ||
        vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_VSET
      ) {
        this.unitSubunit.text = null;
        this.unitSubunit.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.METER_TYPE) {
        this.meterType.text = null;
      } else if (vsetCode === 'party') {
        this.party.name = null;
      } else if (vsetCode === 'meterNo') {
        this.meterNo = null;
      }
    },
    // showHidePartyModal(flag) {
    //   // setTimeout(() => {
    //   //   this.eventBus.$emit('userType', 'CUST');
    //   // }, 0);
    //   this.showPartyModal = flag;
    // },
    showhideFmsMeterModal(flag) {
      if(!flag){
        this.getAllFmsMeterData();
      }
      this.modalFlag = false;
      this.addFmsMeterModal = flag;
    },

    getAllFmsMeterData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        entity_type: this.entityType.text,
        entity_id: this.unitSubunit.value,
        start_date: this.startDate,
        end_date: this.endDate,
        party_id: this.party.id,
        meter_no: this.meterNo,
        meter_type: this.meterType.text,
        active: this.isActive
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getFmsMeterDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.fmsMeterDetailsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearAllData() {
      this.entityType = {
        value: null,
        text: null
      };
      this.unitSubunit = {
        value: null,
        text: null
      };
      (this.startDate = null),
        (this.endDate = null),
        (this.party = {
          name: null,
          id: null
        });
      (this.meterNo = null),
        (this.meterType = {
          value: null,
          text: null
        }),
        (this.isActive = null);
        this.fmsMeterDetailsData = []
        
    },
    rowSelected(item) {
      this.meterId = item.id;
      this.addEditFlag = true;
      this.addFmsMeterModal = true;
      // this.getFmsMeterDetailsById(item.id);
    },
    getFmsMeterDetailsById() {
      let payload = {
        id: this.meterId
      };
      this.loading = true;
      this.$store
        .dispatch('masterData/getFmsMeterDetailsById', payload)
        .then(response => {
          if (response.status === 200) {
            this.fmsMeterData = response.data.data;
            this.modalFlag = true;
            this.addFmsMeterModal = true;
            
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
